import { Field, Label, Switch } from '@headlessui/react';

interface ToggleSwitchProps {
    enabled: boolean;
    onChange: any;
    label: string;
}

export default function ToggleSwitch({ enabled, onChange, label }: ToggleSwitchProps) {
    return (
        <Field className="mb-[30px] flex items-center">
            <Switch
                checked={enabled}
                onChange={onChange}
                className={`${enabled ? 'rmc-bg-primary-2' : 'rmc-bg-base-3'}
        group relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer items-center rounded-full`}
            >
                <span
                    className={`${
                        enabled ? 'translate-x-6' : 'translate-x-1'
                    } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
            </Switch>
            <Label className="ml-3 text-sm rmc-text-base-1 font-medium">{label}</Label>
        </Field>
    );
}
