import { DocumentationModel, SettingModel } from '../service/database';
import Markdown from 'react-markdown';
import rehypeSlug from 'rehype-slug';
import rehypeShiftHeading from 'rehype-shift-heading';
// @ts-ignore
import rehypeFigure from 'rehype-figure';
import React from 'react';
import './sidebar.css';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

export function Documentation({
    documentation,
    setting,
}: {
    documentation: DocumentationModel;
    setting: SettingModel;
}) {
    const breadcumbsDocumentation = [];

    let currentDoc: DocumentationModel | undefined = documentation;
    while (true) {
        currentDoc && breadcumbsDocumentation.unshift(currentDoc);
        if (!currentDoc?.parentDocumentation) break;
        currentDoc = documentation.parentDocumentation;
    }

    return (
        <>
            <div className="mx-auto max-w-5xl">
                <nav className="flex my-2 mx-8">
                    <ol className="flex flex-1 items-center space-x-4 block md:hidden">
                        <li>
                            <div className="flex">
                                <img
                                    loading="lazy"
                                    className="h-4"
                                    src={setting.siteLogo}
                                    alt={`${setting.siteName} logo`}
                                />
                            </div>
                        </li>
                        <li className="block md:hidden">
                            <div className="flex">
                                <ChevronRightIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                <a href="/docs" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                    Docs
                                </a>
                            </div>
                        </li>
                    </ol>
                    <ol className="flex flex-1 items-center space-x-4 hidden md:flex">
                        <li>
                            <div className="flex">
                                <a href="/docs" className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                    Docs
                                </a>
                            </div>
                        </li>
                        {breadcumbsDocumentation.map((documentation, index) => (
                            <li>
                                <div className="flex items-center">
                                    <ChevronRightIcon
                                        className="h-5 w-5 flex-shrink-0 text-gray-400"
                                        aria-hidden="true"
                                    />
                                    <button
                                        title={documentation.title}
                                        className={`ml-4 text-sm font-medium text-gray-500 hover:text-gray-700 ${index === breadcumbsDocumentation.length - 1 ? '' : 'w-32 truncate'}`}
                                    >
                                        {documentation.title}
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ol>
                    <a
                        className="hidden items-center gap-1 md:flex p-1 hover:bg-gray-100 rounded-md"
                        href={setting.githubProfile}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="grid h-6 w-6 place-items-center rounded-full text-black dark:text-gray-200">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <path d="M9 19c-5 1.5-5-2.5-7-3m14 6v-3.87a3.37 3.37 0 0 0-.94-2.61c3.14-.35 6.44-1.54 6.44-7A5.44 5.44 0 0 0 20 4.77 5.07 5.07 0 0 0 19.91 1S18.73.65 16 2.48a13.38 13.38 0 0 0-7 0C6.27.65 5.09 1 5.09 1A5.07 5.07 0 0 0 5 4.77a5.44 5.44 0 0 0-1.5 3.78c0 5.42 3.3 6.61 6.44 7A3.37 3.37 0 0 0 9 18.13V22"></path>
                            </svg>
                        </div>
                        GitHub
                    </a>
                </nav>

                <header className="flex">
                    <h1 className="text-2xl md:text-3xl mt-2 mb-4 md:mt-20 md:mb-10 mx-8 flex-1 font-semibold text-gray-900">
                        {documentation.title}
                    </h1>
                </header>
                <section className="flex">
                    <article className="flex-1 bg-white">
                        <div className="mx-8 max-w-prose">
                            <section className="reader">
                                <Markdown
                                    rehypePlugins={[
                                        rehypeSlug,
                                        [rehypeShiftHeading, { shift: 1 }],
                                        [rehypeFigure, { className: 'caption' }],
                                    ]}
                                >
                                    {documentation.content}
                                </Markdown>
                            </section>
                        </div>
                    </article>
                </section>
            </div>
        </>
    );
}
