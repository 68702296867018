import React from 'react';
import Highlighter from './components/highlighter';
import { Link } from 'react-router-dom';

export default function Hero() {
    const signupUrl = process.env.REACT_APP_SIGNUP_URL as string;
    return (
        <div className="relative isolate overflow-hidden rmc-bg-primary-4">
            <div className="mx-auto max-w-[1240px] px-6 mt-[72px] lg:px-8">
                <div className="mx-auto max-w-4xl">
                    <div className="mt-24 sm:mt-32 lg:mt-16"></div>
                    <h1 className="mt-34 tracking-normal text-center text-6xl font-extrabold line-height-[61px] rmc-text-base-1">
                        <Highlighter colored={true}>Analytics and reader feedback for blogs and docs</Highlighter>
                    </h1>
                    <p className="mt-6 text-xl rmc-text-base-1 max-w-2xl m-auto text-center">
                        <span className="leading-7">
                            <Highlighter colored={false}>
                                Combine traditional analytics with reader feedback. Understand what your readers love,
                                improve content quality, and boost engagement. Privacy friendly. Cookie free.
                            </Highlighter>
                        </span>
                    </p>
                    <div className="mt-8 items-center font-medium text-center text-lg w-fit m-auto gap-6 flex max-md:flex-col-reverse max-md:w-full">
                        {/* <button className="py-4 px-8 rounded-lg border rmc-border-neutral-2 hover:rmc-bg-secondary-3 max-md:w-full">
                            View Demo
                        </button>*/}
                        <Link
                            to={signupUrl}
                            className="py-4 px-8 rmc-bg-primary-2 hover:rmc-bg-primary-3 rounded-lg max-md:w-full"
                        >
                            Start free trial
                        </Link>
                    </div>
                </div>
                <div className="mx-auto mt-[120px] max-w-4xl flex justify-center gap-4 font-bold text-[13px] max-sm:flex-col max-sm:w-fit max-sm:items-center max-sm:mt-10">
                    <div className="py-[3px] px-2.5 rmc-bg-secondary-2 rounded-[30px] flex items-center">Bloggers</div>
                    <div className="py-[3px] px-2.5 rmc-bg-secondary-2 rounded-[30px] flex items-center">
                        Technical writers
                    </div>
                    <div className="py-[3px] px-2.5 rmc-bg-secondary-2 rounded-[30px] flex items-center">
                        AI content writer
                    </div>
                </div>
            </div>
        </div>
    );
}
