import { ArticleModel, SettingModel } from '../service/database';
import { Helmet } from 'react-helmet';
import { seoService } from '../service/seoService';
import React, { useEffect } from 'react';
import { SectionsRenderer } from './sectionsRenderer';
import { buildArticlesListUrl } from '../urlBuilder';
import { onlyUnique } from '../onlyUnique';
import { getBlogComponents } from '../service/registerBlogComponent';
import { useLocation } from 'react-router-dom';

export function ArticleListRenderer({
    articles,
    locale,
    setting,
}: {
    articles: ArticleModel[];
    locale: string;
    setting: SettingModel;
}) {
    const rootUrl = process.env.REACT_APP_ROOT_URL as string;
    const pageUrl = rootUrl + buildArticlesListUrl(locale, setting);
    const alternativesLocalesPageAvailable = articles.map((a) => a.locale).filter(onlyUnique);
    const ArticlesList = getBlogComponents().articlesListComponent;
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div>
            <Helmet
                htmlAttributes={{
                    lang: locale,
                }}
            >
                {seoService.basicMetaTags({
                    pageUrl: pageUrl,
                    description: setting.blogDescription,
                    title: setting.blogName,
                    image: seoService.defaultImage(rootUrl),
                })}
                {seoService.opengraphLocales(locale as string, alternativesLocalesPageAvailable as string[])}
                {seoService.opengraphWebsiteMetaTags()}
                {seoService.twitterMetaTags(
                    {
                        pageUrl: pageUrl,
                        description: setting.blogDescription,
                        title: setting.blogName,
                        image: seoService.defaultImage(rootUrl),
                    },
                    {
                        siteAccount: seoService.defaultTwitterSiteAccount(),
                        creatorAccount: seoService.defaultTwitterCreatorAccount(),
                    },
                )}
            </Helmet>
            <div>
                <SectionsRenderer sections={setting.blogSectionsBefore} locale={locale} />
                <ArticlesList articles={articles} locale={locale} setting={setting} />
                <SectionsRenderer sections={setting.blogSectionsAfter} locale={locale} />
            </div>
        </div>
    );
}
