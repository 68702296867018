import React from 'react';
import { Disclosure } from '@headlessui/react';

const FaqData = [
    {
        index: '01',
        question: 'How does Uniqrate collect feedback?',
        answer: 'Uniqrate uses a non-intrusive feedback “island” that integrates seamlessly into your website. Readers can rate, comment, and engage with your content directly without leaving the page, ensuring their experience is uninterrupted while giving you valuable insights.',
    },
    {
        index: '02',
        question: 'Is Uniqrate GDPR-compliant and privacy-friendly?',
        answer: "Yes, Uniqrate is fully GDPR-compliant. It is also cookie-free and banner-free, meaning it respects your readers' privacy while still providing you with actionable data. You can host it in the US or EU to ensure compliance with data protection laws.",
    },
    {
        index: '03',
        question: 'Do I need coding skills to install Uniqrate?',
        answer: "No, you don’t need coding skills to install Uniqrate. It's a plug-and-play solution that integrates easily with your website. The setup process is simple and user-friendly, with customization options available to match your brand's design.",
    },
    {
        index: '04',
        question: 'How is Uniqrate different from other analytics tools like Google Analytics?',
        answer: 'Unlike traditional tools like Google Analytics, Uniqrate focuses on providing content-specific insights by combining standard analytics with direct reader feedback. While Google Analytics tracks user behavior, Uniqrate gives you more detailed, actionable data on how your content performs, including how your audience feels about it.',
    },
    {
        index: '05',
        question: 'What kind of perks can I offer to readers in exchange for their feedback?',
        answer: 'You can offer various perks to encourage reader engagement, such as downloadable ebooks, checklists, exclusive guides, or early access to new content. These incentives motivate readers to leave valuable feedback, while also helping to grow your email list.',
    },
];

export default function Faq() {
    return (
        <div className="rmc-bg-primary-4 px-40 pt-[120px] pb-[120px] max-lg:px-8">
            <div className={'max-w-[1240px] m-auto'}>
                <h1 className={'rmc-text-base-1 text-[48.8px] font-bold text-center  m-auto mb-6 leading-none'}>
                    Frequently asked questions
                </h1>
                <div className={'flex flex-col'}>
                    {FaqData.map((data, i) => (
                        <Disclosure key={i} as="div" className="pt-6" defaultOpen={i === 0}>
                            {({ open }) => (
                                <div className={'border-b rmc-border-secondary-3 pb-8'}>
                                    <div>
                                        <div>
                                            <Disclosure.Button className="flex w-full items-start justify-start text-left max-w-[50rem] m-auto gap-10">
                                                <span
                                                    className={
                                                        'text-[25px] font-semibold leading-[25px] tracking-[-0.55px] rmc-text-primary-2 w-8'
                                                    }
                                                >
                                                    {data.index}
                                                </span>
                                                <span className="text-base font-semibold leading-7 flex ">
                                                    {data.question}
                                                    <span className="ml-2 flex h-7 items-center">
                                                        {open ? (
                                                            <img
                                                                loading="lazy"
                                                                alt={'arrow up'}
                                                                src={'/arrow_drop_up.svg'}
                                                                aria-hidden="true"
                                                                className={'h-6 w-6'}
                                                            />
                                                        ) : (
                                                            <img
                                                                loading="lazy"
                                                                alt={'arrow down'}
                                                                src={'/arrow_drop_down.svg'}
                                                                aria-hidden="true"
                                                                className={'h-6 w-6'}
                                                            />
                                                        )}
                                                    </span>
                                                </span>
                                            </Disclosure.Button>
                                        </div>

                                        <Disclosure.Panel as="div" className="mt-4 max-w-[50rem] m-auto pl-[72px]">
                                            <p className="text-base leading-7">{data.answer}</p>
                                        </Disclosure.Panel>
                                    </div>
                                </div>
                            )}
                        </Disclosure>
                    ))}
                </div>
            </div>
        </div>
    );
}
