import { ArticleModel, SettingModel } from '../service/database';
import { Helmet } from 'react-helmet';
import { seoService } from '../service/seoService';
import React, { useEffect } from 'react';
import { SectionsRenderer } from './sectionsRenderer';
import { buildArticleUrlFromPath } from '../urlBuilder';
import { getBlogComponents } from '../service/registerBlogComponent';
import { useLocation } from 'react-router-dom';

export function ArticleRenderer({
    allArticles,
    article,
    setting,
}: {
    allArticles: ArticleModel[];
    article: ArticleModel;
    setting: SettingModel;
}) {
    const rootUrl = process.env.REACT_APP_ROOT_URL as string;
    const pageUrl = rootUrl + buildArticleUrlFromPath(article.locale as string, article.path as string, setting);
    const alternativesLocalesPageAvailable = allArticles
        .filter((a) => a.ref === article.ref && a.path !== article.path)
        .map((p) => p.locale);
    const Article = getBlogComponents().articleComponent;
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return (
        <div>
            <Helmet
                htmlAttributes={{
                    lang: article.locale,
                }}
            >
                {seoService.basicMetaTags({
                    pageUrl: pageUrl,
                    description: getFirstWords(article.content || '', 20),
                    title: article.title,
                    image: article.seo.image ? `${rootUrl}${article.seo.image}` : seoService.defaultImage(rootUrl),
                })}
                {seoService.opengraphLocales(article.locale as string, alternativesLocalesPageAvailable as string[])}
                {seoService.opengraphArticleMetaTags({
                    publishedAt: article.publishedAt,
                    updatedAt: article.updatedAt,
                    author: article.author.firstname + ' ' + article.author.lastname,
                })}
                {seoService.twitterMetaTags(
                    {
                        pageUrl: pageUrl,
                        description: getFirstWords(article.content || '', 20),
                        title: article.title,
                        image: article.seo.image ? `${rootUrl}${article.seo.image}` : seoService.defaultImage(rootUrl),
                    },
                    {
                        siteAccount:
                            article.seo.twitter?.siteAccount ||
                            article.author.twitterAccount ||
                            seoService.defaultTwitterSiteAccount(),
                        creatorAccount: article.author.twitterAccount || seoService.defaultTwitterCreatorAccount(),
                    },
                )}
            </Helmet>
            <div>
                <SectionsRenderer sections={setting.blogSectionsBefore} locale={article.locale as string} />
                <Article url={pageUrl} article={article} setting={setting} />
                <SectionsRenderer sections={setting.blogSectionsAfter} locale={article.locale as string} />
            </div>
        </div>
    );
}

// Function that get 200 first words of a string
function getFirstWords(str: string, n: number) {
    return str.split(' ').slice(0, n).join(' ');
}
