export default function ToolTargets() {
    return (
        <section className={'rmc-bg-primary-4 pt-[80px] pb-16 px-40 max-lg:px-8'}>
            <div className={'max-w-[1240px] m-auto'}>
                <h1 className={'rmc-text-base-1 text-[48.8px] font-bold text-center m-auto mb-6 leading-none'}>
                    The All-in-One Analytics Tool for Bloggers, Technical writers and AI content writers
                </h1>
                <p className={'rmc-text-base-1 text-xl leading-8 m-auto text-center max-w-3xl'}>
                    Uniqrate is designed with one goal in mind: to help writers like you understand your audience
                    better. Traditional analytics tools focus on numbers, but Uniqrate goes beyond that—combining
                    classic metrics with real reader feedback to help you pinpoint what’s working, what needs
                    improvement, and how to elevate your content’s impact.
                </p>
                <div className={'flex gap-10 justify-center mt-10 max-lg:flex-col max-lg:gap-4'}>
                    <div className={'p-4 flex flex-col gap-1 rmc-bg-secondary-2 rounded-3xl w-1/3 max-lg:w-full'}>
                        <p className={'text-sm'}>Increase conversion and CTR</p>
                        <p className={'flex justify-between'}>
                            <span className={'text-xl font-semibold'}>For Bloggers</span>
                        </p>
                    </div>
                    <div className={'p-4 flex flex-col gap-1 rmc-bg-secondary-2 rounded-3xl w-1/3 max-lg:w-full'}>
                        <p className={'text-sm'}>Improve content accuracy</p>
                        <p className={'flex justify-between'}>
                            <span className={'text-xl font-semibold'}>For Technical Writers</span>
                        </p>
                    </div>
                    <div className={'p-4 flex flex-col gap-1 rmc-bg-secondary-2 rounded-3xl w-1/3 max-lg:w-full'}>
                        <p className={'text-sm'}>Limit SEO risks linked to AI-generated content</p>
                        <p className={'flex justify-between'}>
                            <span className={'text-xl font-semibold'}>For AI Content Writers</span>
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}
