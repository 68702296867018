import React, { useState } from 'react';

const TABS = ['Dashboard', 'Inbox', 'Content', 'Settings'];

export default function ForYou() {
    const [currentTab, setCurrentTab] = useState(TABS[1]);

    const computeNewPreviousTab = () => {
        if (currentTab === 'Dashboard') {
            setCurrentTab('Settings');
        } else {
            setCurrentTab(TABS[TABS.indexOf(currentTab) - 1]);
        }
    };

    const computeNewNextTab = () => {
        if (currentTab === 'Settings') {
            setCurrentTab('Dashboard');
        } else {
            setCurrentTab(TABS[TABS.indexOf(currentTab) + 1]);
        }
    };

    const getImage = () => {
        switch (currentTab) {
            case 'Dashboard':
                return <img loading="lazy" src={'home.webp'} srcSet={'home@2x.webp 2x'} alt={'Uniqrate dashboard'} />;
            case 'Inbox':
                return <img loading="lazy" src={'inbox.webp'} srcSet={'inbox@2x.webp 2x'} alt={'Uniqrate inbox'} />;
            case 'Content':
                return (
                    <img loading="lazy" src={'content.webp'} srcSet={'content@2x.webp 2x'} alt={'Uniqrate content'} />
                );
            case 'Settings':
                return (
                    <img
                        loading="lazy"
                        src={'settings.webp'}
                        srcSet={'settings@2x.webp 2x'}
                        alt={'Uniqrate settings'}
                    />
                );
        }
    };

    const getDescription = () => {
        switch (currentTab) {
            case 'Dashboard':
                return (
                    <div>
                        <p>All your analytics at a glance.</p>
                        <p>What you missed since last visit.</p>
                    </div>
                );
            case 'Inbox':
                return (
                    <div>
                        <p>Get comments. Take notes.</p>
                        <p>Full user context - user location, UTMs and navigation history.</p>
                        <p>Read / unread management.</p>
                    </div>
                );
            case 'Content':
                return (
                    <div>
                        <p>Visualize your content in detail. Page by page.</p>
                    </div>
                );

            case 'Settings':
                return (
                    <div>
                        <p>Configure every aspect of the Dynamic Island.</p>
                        <p>Smart trigger. Light or Dark.</p>
                        <p>What you are asking your readers.</p>
                    </div>
                );
        }
    };
    return (
        <div className={'bg-white mb-16 mx-40 max-lg:mx-0'}>
            <div
                className={'rmc-bg-secondary-2 m-auto flex flex-col p-8 rounded-3xl max-w-[1240px] max-lg:rounded-none'}
            >
                <p className="py-[3px] px-2.5 rmc-bg-primary-5 rounded-[30px] m-auto text-[13px] font-bold">For you</p>
                <h1 className={'font-semibold text-[39px] text-center mt-4 mb-12 leading-[39px] max-lg:mb-4'}>
                    Analytics and feedback in one place
                </h1>
                <div
                    className={
                        'flex max-[600px]:flex-col justify-center gap-8 mb-6 max-md:space-y-2 max-md:gap-0 max-md:space-x-6 max-[600px]:space-x-0 items-end max-[600px]:items-center'
                    }
                >
                    {TABS.map((tab, i) => (
                        <div key={tab}>
                            <p
                                key={tab}
                                onClick={() => setCurrentTab(tab)}
                                className={`max-[600px]:w-full  leading-5 cursor-pointer text-xl font-semibold ${currentTab === tab ? 'underline rmc-text-base-1' : 'rmc-text-base-2'}`}
                            >
                                {tab}
                            </p>
                            {<p className={'max-[600px]:hidden basis-full h-0'}></p>}
                        </div>
                    ))}
                </div>
                <div
                    className={
                        'flex flex-col min-h-[750px] max-lg:min-h-[650px] max-[1320px]:min-h-[640px] max-[1190px]:min-h-[590px] max-md:min-h-[480px] max-sm:min-h-[370px]'
                    }
                >
                    <div className={'flex self-center justify-around w-full mb-6 max-md:justify-center'}>
                        <img
                            loading="lazy"
                            alt={'left arrow'}
                            className={'left-10 relative cursor-pointer max-sm:left-5'}
                            src={'/circle_arrow_left_black.svg'}
                            onClick={() => computeNewPreviousTab()}
                        />
                        {getImage()}
                        <img
                            loading="lazy"
                            alt={'right arrow'}
                            className={'right-10 relative cursor-pointer max-sm:right-5'}
                            src={'/arrow_circle_right.svg'}
                            onClick={() => computeNewNextTab()}
                        />
                    </div>
                    <div className={'text-center rmc-text-base-1 text-xl leading-8'}>{getDescription()}</div>
                </div>
            </div>
        </div>
    );
}
