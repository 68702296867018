import React from 'react';
import { Link } from 'react-router-dom';

export default function FreeTrial() {
    return (
        <div className={'rmc-bg-secondary-2 pt-16 pb-16 px-40 max-lg:px-8'}>
            <div className={'max-w-[1240px] m-auto flex flex-col '}>
                <h1 className="tracking-normal text-center text-6xl font-extrabold line-height-[61px] rmc-text-base-1 mb-4">
                    Start Your Free Trial Today
                </h1>
                <p className={'rmc-text-base-1 text-xl leading-8 m-auto text-center mb-10'}>
                    Discover a completely new way to gather analytics and feedback from your readers, all while
                    respecting their privacy. Enjoy a 14-day free trial and take advantage of our exclusive launch
                    pricing.
                </p>
                <Link
                    to={process.env.REACT_APP_SIGNUP_URL as string}
                    className="text-center py-4 px-8 rmc-bg-primary-2 hover:rmc-bg-primary-3 w-fit m-auto rounded-lg max-md:w-full font-medium text-lg"
                >
                    Start free trial
                </Link>
            </div>
        </div>
    );
}
